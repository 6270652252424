import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Row, Col, Pagination, Input } from "antd";
import styled from "styled-components";
import * as Theme from "../Theme";
import useDimension from "../hooks/use-dimension";
import { navigate, Link } from "gatsby";
import { Context } from "../AppContext";
import { errorHandler, ErrWorks } from "../errors";
const qs = require("query-string");
import SwapLeftOutlined from "@ant-design/icons/SwapLeftOutlined";
import SwapRightOutlined from "@ant-design/icons/SwapRightOutlined";
import { Search2 } from "@styled-icons/remix-line/Search2";
import SearchBar from "../Components/SearchBar";

function Item(props) {
  const { files, title, id } = props.item;
  const image = files?.[0]?.expected_url;

  return (
    <Col lg={6} md={8} sm={12} xs={24}>
      <ItemWrapper to={`/work/${id}`}>
        <div className="image-wrapper">
          {image ? (
            <img className="image" alt="作品集縮圖" src={image} />
          ) : (
            <div className="image" />
          )}
        </div>
        <div className="title">{title || "---"}</div>
      </ItemWrapper>
    </Col>
  );
}

const ItemWrapper = styled(Link)`
  width: 100%;

  &:hover > .image-wrapper > img {
    transform: scale(1.2);
    opacity: 0.5;
    transition: all 0.3s ease-out;
  }
  &:hover > .title {
    opacity: 0.5;
  }
  & > .image-wrapper {
    margin-bottom: 10px;
    position: relative;
    padding-top: 100%;
    overflow: hidden;
    & > .image {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #e6e6e6;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & > .title {
    color: #000;
    height: 70px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

const PAGINATION = {
  pageSize: 20,
  current: 1,
};

export default function WorksPage(props) {
  const { label } = qs.parse(props.location.search);
  const { dimension, rwd } = useDimension();
  const pad = dimension.innerWidth <= Theme.breakpoints.lg;
  const mobile = dimension.innerWidth <= Theme.breakpoints.xs;
  const app = useContext(Context);
  const { worksLabels: labels } = app.state;
  const [openSearch, setOpenSearch] = useState(false);
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    ...PAGINATION,
    label: label,
    search: "",
  });

  const filterChange = obj => setFilters(prev => ({ ...prev, ...obj }));

  useEffect(() => {
    filterChange({ label, current: 1 });
  }, [label]);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      try {
        let { results, total } = await app.actions.getWorks({
          query: {
            ...(filters.label &&
              filters.label !== "ALL" && { label: filters.label }),
            ...(filters.search && { title: { $regex: filters.search } }),
          },
          paging: {
            offset: (filters.current - 1) * filters.pageSize,
            limit: filters.pageSize,
          },
          sorting: ["-created"],
          projection: { variant: 0, content: 0 },
        });
        setRecords(results);
        setTotal(total);
      } catch (err) {
        errorHandler(err);
      }
      app.actions.setLoading(false);
    })();
  }, [filters]);

  const itemRender = useCallback((current, type, originalElement) => {
    if (type === "prev") {
      return <SwapLeftOutlined style={{ color: "#9b9b9b", fontSize: 20 }} />;
    }
    if (type === "next") {
      return <SwapRightOutlined style={{ color: "#9b9b9b", fontSize: 20 }} />;
    }

    return originalElement;
  }, []);

  return (
    <Wrapper isPad={pad} rwd={rwd}>
      <SearchBar
        visible={openSearch}
        defaultValue={filters.search}
        onSearch={value => filterChange({ search: value })}
      />
      <div className="constraint">
        <h2>WORKS</h2>

        <Categories>
          {["ALL"].concat(labels).map((label, idx) => (
            <Link
              className={`label ${filters.label === label ? "active" : ""}`}
              key={idx}
              to={`/works/?label=${label}`}
            >
              {label}
            </Link>
          ))}
          <button
            className="label"
            style={{
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setOpenSearch(prev => !prev)}
          >
            <div style={{ marginRight: 2 }}>搜尋印件</div>
            <Search2 size={16} />
          </button>
        </Categories>

        {filters.search && (
          <div style={{ color: "#bbb", marginBottom: 40, textAlign: "center" }}>
            搜尋條件：{filters.search}
          </div>
        )}

        <Row gutter={[40, 40]} style={{ marginBottom: 40 }}>
          {records.map((record, idx) => (
            <Item key={idx} item={record} />
          ))}
        </Row>

        <Pagination
          current={filters.current}
          pageSize={filters.pageSize}
          total={total}
          onChange={(page, pageSize) =>
            filterChange({ current: page, pageSize })
          }
          showSizeChanger={false}
          itemRender={itemRender}
          style={{ marginBottom: 40, textAlign: "center" }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  flex: 1;
  padding-top: 50px;

  & > .constraint {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0 auto;
  }

  & h2 {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  & .ant-pagination {
    & > .ant-pagination-item {
      border: 0;
    }
    & > .ant-pagination-item > a {
      color: #9b9b9b;
    }
    & > .ant-pagination-item-active > a {
      color: #000;
    }
    & > .ant-pagination-next,
    .ant-pagination-prev {
      vertical-align: sub;
    }
  }

  @media screen and (max-width: ${Theme.centerContentMaxWidth}) {
    padding: 40px;
  }

  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    padding: 20px;
  }
`;

const Categories = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto 40px;

  & > .label {
    margin-bottom: 10px;
    color: #bbb;
    /* border-right: solid 1px #aaa; */
    margin-right: 8px;
    border: 0;
    background-color: transparent;
    padding: 0;
  }

  & > .label.active,
  .label:hover {
    color: #444;
    transition: color 0.3s ease-in;
  }

  & > .label:last-child {
    border-right: 0;
  }

  & > .label::after {
    content: "।";
    margin-left: 8px;
  }
`;
